import axios from "axios"

import {
  AxiosError,
  AxiosResponse,
} from "axios"

import {
  EntireApplication,
  ApplicationResponse,
  CategoryLookup,
  APIResponseBody,
  APIError,
} from "@/models/ApplicationForm"

export class ApplicationFormService {
  private apiUrl = "";

  constructor (apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  /**
   * @name postApplication
   * @input app - An EntireApplication object containing all data regarding the job application
   * @desc Queries the api and attempts to submit a new job application
   * @returns 0 if the query was successful, 1 if it failed
   */
  async postApplication (app: EntireApplication): Promise<ApplicationResponse> {
    const sanitizedApp = this.sanitizeApplication(app);

    try {
      // Calls the API
      const result = await axios.post<EntireApplication>(
        this.apiUrl + "/postApplication", sanitizedApp
      ).then (
        (response: AxiosResponse) => {
          console.log("PostApplication Response: " + response.status);
          console.log(response);

          const par: ApplicationResponse = {
            status: response.status,
            body:   null
          };
          return par;
        },

        (error: AxiosError) => this.handleError(error)
      );

      return result;
    }
    catch (exception) {
      console.error("An exception occurred while submitting the application");
      console.error(exception);

      return {
        status: 500,
        body:   null
      } as ApplicationResponse;
    }
  }


  /**
   * @name postApplicationOverride
   * @input app - An EntireApplication object containing all data regarding the job application
   * @desc Queries the api and attempts to submit a new job application
   * @returns 0 if the query was successful, 1 if it failed
   */
  async postApplicationOverride (app: EntireApplication, overrideCode: string): Promise<ApplicationResponse> {
    const sanitizedApp = this.sanitizeApplication(app);

    try {
      // Calls the API
      const result = await axios.post<EntireApplication>(
        this.apiUrl + "/postApplicationOverride",
        sanitizedApp,
        {
          headers: {
            "Override-Code": overrideCode
          }
        }
      ).then (
        (response: AxiosResponse) => {
          console.log("PostApplication Response: " + response.status);
          console.log(response);

          const par: ApplicationResponse = {
            status: response.status,
            body:   null
          };
          return par;
        },

        (error: AxiosError) => this.handleError(error)
      );

      return result;
    }
    catch (exception) {
      console.error("An exception occurred while submitting the application");
      console.error(exception);

      return {
        status: 500,
        body:   null
      } as ApplicationResponse;
    }
  }

  /**
   * @name getLookupTable
   * @desc Queries the api and attempts to get the lookup table
   * @returns An array containing records in the lookup table
   */
  async getLookupTable(): Promise<CategoryLookup[]> {
    const result = await axios.get<CategoryLookup[]> (
      this.apiUrl + "/getCategoryLookupTable"
    ).then (
      (response: AxiosResponse) => {
        return response.data;
      },
      (error: AxiosError) => {
        console.error("Error from API while getting lookup table");
        console.error(error);
        return [];
      }
    );

    return result;
  }

  /**
   * @name getApiVersion
   * @desc Queries the api for its version number
   * @returns Returns a string containing the API version number
   */
  async getApiVersion(): Promise<string> {
    const result = await axios.get<string> (
        this.apiUrl + "/version"
    ).then (
        (response: AxiosResponse) => {
          return response.data;
        },
        (error: AxiosError) => {
          console.error("Error from API while requesting version");
          console.error(error);
          return "";
        }
    );

    return result;
  }

  private handleError(error: AxiosError): ApplicationResponse {
    console.error("PostApplication Error from API");
    console.error(error);
    // @ts-ignore
    console.error("Error Message: " + error.response?.data.message);

    return {
      // @ts-ignore
      status: error.response.status,
      body: {
        // @ts-ignore
        error:   APIError[error.response?.data.error],
        // @ts-ignore
        message: error.response?.data.message
      }
    };
  }

  /*
   * @name sterilizeApplication
   * @desc Sterilizes the application ensuring that values are null if their parent value is false.
        If a user checks "yes" for a value then fills in data for the follow up question then
        changes the parent "yes" to a "no", the follow up question will still retain that data.
        This function sterilizes the data to prevent this issue.
   */
  private sanitizeApplication(app: EntireApplication): EntireApplication {

    // Casts empty nullable strings into null values
    if (app.application.referralName            == "") { app.application.referralName            = null; }
    if (app.application.explanationForDischarge == "") { app.application.explanationForDischarge = null; }
    if (app.application.otherJobSkills          == "") { app.application.otherJobSkills          = null; }
    if (app.application.jobCertifications       == "") { app.application.jobCertifications       = null; }
    if (app.application.organizationMembership  == "") { app.application.organizationMembership  = null; }


    // Ensures conviction records are empty if convicted boolean is false
    if (!app.application.convictedOfMisdemeanorOrFelony) {
      app.convictionRecords = [];
    }

    // Ensures former employer data is empty if hasFormerEmployerHistory is false
    if (!app.application.hasFormerEmployerHistory) {
      app.formerEmployers = [];
      app.application.currentlyEmployed = false;
      app.application.involuntarilyDischarged = false;
    }

    // Ensures child question values are null if their parent question is false
    if (!app.application.involuntarilyDischarged) { app.application.explanationForDischarge = null; }
    if (!app.application.currentlyEmployed)       { app.application.inquirePresentEmployer  = null; }
    // if (!this.isOtherJobSkillSelected)            { app.application.otherJobSkills          = null; }

    // Strips extra data out of masked values
    app.application.phoneNumber = app.application.phoneNumber.replace(/-/g, '');
    app.application.socialSecurity = app.application.socialSecurity.replace(/-/g, '');
    app.references.map(ref => {
      if (ref.phoneNumber === "") {
        ref.phoneNumber = null;
      }
      if (ref.phoneNumber != null) {
        ref.phoneNumber = ref.phoneNumber.replace(/-/g, '');
      }
    });

    return app;
  }
}
